<template>
  <div>
    <ui-section-header>
      <template v-slot:title>Awaiting approval</template>
    </ui-section-header>

    <table class="table is-fullwidth is-striped is-hoverable">
      <tbody>
        <tr v-for="(location, index) in locations" :key="index">
          <td>
            <div>{{ location.Name }}</div>
            <div>
              <span
                class="tag is-small is-link is-size-7"
                v-for="channel in location.Channels"
                :key="channel.Id"
                >{{ channel.ChannelName }}</span
              >
            </div>
          </td>
          <td>
            <a @click="goToLocation(location.Id)">
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'share']" />
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <Message
      v-if="!isLoading && locations.length === 0"
      :message="'No locations awaiting approval'"
      :type="'is-info'"
    />

    <transition name="fade" mode="out-in">
      <div v-if="isLoading">
        <ui-loader />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import locationProvider from '@/providers/location'

const Message = () => import('@/components/UI/Message')

export default {
  components: {
    Message,
  },

  props: {},

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      isLoading: false,
      locations: [],
    }
  },

  computed: {},

  created() {
    this.getLocationsAwaitingApproval()
  },

  methods: {
    getLocationsAwaitingApproval() {
      let self = this

      if (!self.isLoading) {
        self.isLoading = true

        locationProvider.methods
          .getLocations(self.channelId, '', 0, 100, 1, 0, 0)
          .then((response) => {
            self.locations = response.data.Results
          })
          .finally(() => {
            self.isLoading = false
          })
      }
    },

    goToLocation(locationId) {
      this.$router.push({
        name: `locations-detail`,
        params: { channelId: this.channelId, locationId: locationId },
      })
    },
  },
}
</script>

<style></style>
